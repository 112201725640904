import styled from '@emotion/styled'

export const SCContainer = styled.div`
  min-height: calc(100vh - 144px);

  & .avt {
    background-position: center !important;
    background-repeat: no-repeat;
    background-size: 50px;
    width: 75px;
    height: 75px;
  }
`
