import React, { useContext } from 'react'
import { PremiumUser } from 'models/premium-user'
import { BasicUser } from 'models/basic-user'
import { BaseContext, BaseContextProvider } from 'shared/context/BaseContext'
import { BusinessProfileType, PremiumTemplates, SocialMedia } from 'utils/constants'
import Head from 'shared/components/Head/Head'
import Header from 'components/templates/premium/t/Sections/Header'
import ModelShareData from 'shared/components/SharePlayuModal'
import Footer from 'components/templates/premium/t/Sections/Footer'
import SwiperClass, { EffectFlip, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import ProfileSection from 'components/templates/premium/t/Sections/Content/Profile/Profile'
import SocialMediaSection from 'components/templates/premium/t/Sections/Content/SocialMedia/SocialMedia'
import LogoPlayUBlack from 'assets/images/logo_playu_black-256.png'
import { getTemplateSettings, TemplateSettings } from 'utils/template'
import { CSSBody } from 'components/templates/premium/t/styles'
import { Global } from '@emotion/react'
import { SCContainer } from './styles'

interface LinksDomain {
  links: {
    id: string
    title: string
    contact: BasicUser['contact']
  }[]
}

const user = new PremiumUser({
  id: '1',
  name: 'PlayU',
  firstLastName: 'Company',
  nameToDisplay: 'PlayU',
  slug: 'PlayU.Tips',
  metadata: {
    pageTitle: 'Enlaces',
  },
  avatarUrl: {
    file: {
      url: LogoPlayUBlack,
    },
  },
  template: '',
  businessProfileType: BusinessProfileType.Personal,
  jobs: [
    {
      id: '1',
      title:
        'Aquí podras encontrar todos nuestros tips y recomendaciones, asi como nuestros enlaces útiles y muchos más!',
    },
  ],
  contact: {
    addresses: [],
    email: '',
    phoneNumbers: [''],
    id: '',
    socialMedia: [],
  },
  services: [],
})

const data: LinksDomain = {
  links: [
    {
      id: '1',
      title: 'Tips y Recomendaciones',
      contact: {
        id: '1',
        phoneNumbers: ['51901422305'],
        email: 'hola@playu.pe',
        addresses: [
          {
            id: '1',
            description: 'Lima, Peru',
          },
        ],
        socialMedia: [
          {
            order: 1,
            id: '1',
            link: 'https://youtu.be/i8MrLMSoGN0',
            description: '¿Cómo comparto mi tarjeta digital PlayU?',
            type: SocialMedia.Youtube,
            icon: 'fab fa-youtube',
          },
          {
            order: 2,
            id: '2',
            link: 'https://youtu.be/kAutephjxps',
            description: '¿Cómo pueden guardar mi contacto o tarjeta digital?',
            type: SocialMedia.Youtube,
            icon: 'fab fa-youtube',
          },
          {
            order: 3,
            id: '3',
            link: 'https://youtu.be/pOO_-HqBEFc',
            description: '¿Cómo genero el acceso directo de mi tarjeta digital en mi celular Android?',
            type: SocialMedia.Youtube,
            icon: 'fab fa-youtube',
          },
          {
            order: 4,
            id: '4',
            link: 'https://youtu.be/VJChhgfKpII',
            description: '¿Cómo genero el acceso directo de mi tarjeta digital en mi iPhone?',
            type: SocialMedia.Youtube,
            icon: 'fab fa-youtube',
          },
        ],
      },
    },
    {
      id: '2',
      title: 'Redes Sociales',
      contact: {
        id: '1',
        phoneNumbers: ['+51901422305'],
        email: 'hola@playu.pe',
        addresses: [
          {
            id: '1',
            description: 'Lima, Peru',
          },
        ],
        socialMedia: [
          {
            order: 1,
            id: '1',
            link: 'https://www.facebook.com/playu.shop',
            description: 'Síguenos en Facebook',
            type: SocialMedia.Facebook,
            icon: 'fab fa-facebook-f',
          },
          {
            order: 2,
            id: '2',
            link: 'https://instagram.com/playu.shop',
            description: 'Síguenos en Instragram',
            type: SocialMedia.Instagram,
            icon: 'fab fa-instagram',
          },
          {
            order: 3,
            id: '3',
            link: 'https://www.tiktok.com/@playu.shop',
            description: 'Síguenos en Tiktok',
            type: SocialMedia.Tiktok,
            icon: 'fab fa-tiktok',
          },
          {
            order: 4,
            id: '4',
            link: 'https://www.youtube.com/channel/UCne87oRuqn9eEIZQ63A5EQg',
            description: 'Nuestro Canal Oficial',
            type: SocialMedia.Youtube,
            icon: 'fab fa-youtube',
          },
          {
            order: 5,
            id: '5',
            link: 'https://www.linkedin.com/company/playu/',
            description: 'Nuestro LinkedIn',
            type: SocialMedia.Linkedin,
            icon: 'fab fa-linkedin',
          },
          {
            order: 6,
            id: '6',
            link: 'https://api.whatsapp.com/send?phone=51901422305&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20PlayU.',
            description: '¡Contáctanos por whatsapp!',
            type: SocialMedia.Whatsapp,
            icon: 'fab fa-whatsapp',
          },
        ],
      },
    },
  ],
}

interface LayoutProps {
  settings: TemplateSettings
}

const Layout: React.FC<LayoutProps> = ({ settings }: LayoutProps) => {
  const { setSwiper } = useContext(BaseContext)

  const dropdownMenu: {
    index: number
    label: string
    value: string
  }[] = []

  data.links.map((el, index) => dropdownMenu.push({ index, label: el.title, value: '' }))

  return (
    <>
      <Header
        user={user}
        template={PremiumTemplates.TStyle_Bone}
        settings={settings}
        menuOptions={{ dropdownMenu, enableAddUser: false }}
      />
      {/* <Global styles={CSSArrowsNavigationAnimation} /> */}
      <SCContainer>
        <div className="Main-section container">
          <ProfileSection user={user} hideFollowMe hideContactIcons />
          <div>
            <Swiper
              modules={[EffectFlip, Navigation]}
              onAfterInit={(inst: any) => {
                const update = setInterval(() => inst.update(), 250)
                setTimeout(() => clearInterval(update), 1000)
              }}
              onInit={(inst: SwiperClass) => {
                setSwiper(inst)
              }}
              autoHeight
              allowTouchMove={false}
              effect="flip"
              loop
              preventClicks={false}
            >
              {data.links.map(({ id, title, contact }) => (
                <SwiperSlide key={id}>
                  <SocialMediaSection title={title} contact={contact} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </SCContainer>
      <ModelShareData />
      <Footer />
    </>
  )
}

export const Links: React.FC = () => {
  const settings = getTemplateSettings(PremiumTemplates.TStyle_Bone)
  return (
    <BaseContextProvider values={{ settings }}>
      <Global styles={CSSBody(settings.palette)} />
      <Head titleTemplate="Enlaces" />
      <Layout settings={settings} />
    </BaseContextProvider>
  )
}
